import styled from '@emotion/styled';

const SubtitlePart = styled.div({
  boxSizing: 'border-box',
  flex: '0 0 auto',
  flexBasis: '50%',
  maxWidth: '50%',
  paddingLeft: 'calc(1rem * 0.5)',
  paddingRight: 'calc(1rem * 0.5)',
});

export default SubtitlePart;
