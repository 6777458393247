import Eyes2Ears from '../Eyes2Ears';
import Truncate from 'components/Truncate';
import { getLiveUrl } from 'utils/url';
import { isEmpty } from 'lodash-es';
import { PureComponent } from 'react';
import { STATION_TYPE } from 'constants/stationTypes';

export default class LiveE2E extends PureComponent {
  static propTypes = {
    requestStation: PropTypes.func.isRequired,
    station: PropTypes.object,
    stationId: PropTypes.number.isRequired,
  };

  static defaultProps = { station: {} };

  componentDidMount() {
    const { stationId, requestStation, station } = this.props;
    if (!station || isEmpty(station)) requestStation(stationId);
  }

  render() {
    const { stationId, station } = this.props;

    return (
      <Eyes2Ears
        stationId={stationId}
        stationType={STATION_TYPE.LIVE}
        title={<Truncate>{station?.name ?? ''}</Truncate>}
        url={getLiveUrl(stationId, station?.name ?? '')}
      >
        <Truncate lines={2}>{station?.description ?? ''}</Truncate>
      </Eyes2Ears>
    );
  }
}
