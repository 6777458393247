import Eyes2Ears from '../Eyes2Ears';
import Truncate from 'components/Truncate';
import { get } from 'lodash-es';
import { PureComponent } from 'react';
import { STATION_TYPE } from 'constants/stationTypes';

export default class PodcastE2E extends PureComponent {
  static propTypes = {
    getEpisodeData: PropTypes.func,
    getPodcastData: PropTypes.func.isRequired,
    podcast: PropTypes.object,
    podcastId: PropTypes.number.isRequired,
    episodeId: PropTypes.number,
    episodes: PropTypes.object,
  };

  static defaultProps = { podcast: {} };

  componentDidMount() {
    const {
      podcast,
      getEpisodeData,
      getPodcastData,
      podcastId,
      episodeId,
      episodes,
    } = this.props;
    if (!podcast || !Object.keys(podcast).length) getPodcastData(podcastId);
    if (episodeId && !episodes[episodeId]) getEpisodeData(episodeId);
  }

  render() {
    const { podcastId, podcast, episodeId, episodes = {} } = this.props;

    let title;
    let description;

    if (episodeId) {
      const correctEpisode = episodes[episodeId];
      title =
        correctEpisode ?
          `${correctEpisode?.title} - ${podcast?.title ?? ''}`
        : '';
      description = correctEpisode?.description ?? '';
    } else {
      title = podcast?.title ?? '';
      description = podcast?.description ?? '';
    }

    const clampedTitle = <Truncate>{title}</Truncate>;
    const clampedDescription = <Truncate lines={2}>{description}</Truncate>;

    return (
      <Eyes2Ears
        stationId={podcastId}
        stationType={STATION_TYPE.PODCAST}
        title={clampedTitle}
        trackId={episodeId}
        url={get(podcast, 'url')}
      >
        {clampedDescription}
      </Eyes2Ears>
    );
  }
}
