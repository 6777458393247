import AddThis from 'components/AddThis/AddThis';
import styled from '@emotion/styled';

const AddThisBottom = styled(AddThis)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.gray.light}`,
  margin: '3rem 0',
  padding: '3rem',
  textAlign: 'center',
}));

export default AddThisBottom;
