import getScript from 'utils/getScript';
import useMount from 'hooks/useMount';
import { FunctionComponent } from 'react';
import { getLangPrefix } from 'state/i18n/selectors';
import { useSelector } from 'react-redux';

async function initialize(lang: string) {
  if (!window.addthis) {
    window.addthis_config = window.addthis_config || {};
    window.addthis_config.lang = lang;
    window.addthis_config.pubid = 'iheartradio';
    await getScript(
      '//s7.addthis.com/js/300/addthis_widget.js#domready=1',
      'addthis',
    );
  }

  if (window.addthis?.layers?.refresh) {
    window.addthis.layers.refresh();
  }
}

type Props = {
  link: string;
  title: string;
};

const AddThis: FunctionComponent<Props> = ({ link, title }) => {
  const lang = useSelector(getLangPrefix);

  useMount(() => {
    initialize(lang);
  });

  return (
    <div
      className="addthis_custom_sharing"
      css={{ textAlign: 'right' }}
      data-title={title}
      data-url={link}
    />
  );
};

export default AddThis;
