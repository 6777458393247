import Eyes2Ears from '../Eyes2Ears';
import Truncate from 'components/Truncate';
import url from 'url';
import { Playlist } from 'state/Playlist/types';
import { PureComponent } from 'react';
import { REQUEST_STATE } from 'state/Playlist/constants';
import { STATION_TYPE } from 'constants/stationTypes';

export type Props = {
  playlist?: Playlist;
  playlistId: string;
  playlistUserId: string;
  requestPlaylist: (playlist: {
    playlistId: string;
    playlistUserId: string;
  }) => void;
};

export default class PlaylistE2E extends PureComponent<Props> {
  componentDidMount() {
    const { playlist, requestPlaylist, playlistId, playlistUserId } =
      this.props;

    if (
      !playlist ||
      !Object.keys(playlist).length ||
      playlist.requestState === REQUEST_STATE.NOT_REQUESTED
    ) {
      requestPlaylist({ playlistId, playlistUserId });
    }
  }

  render() {
    const { playlist } = this.props;
    const {
      seedId,
      name,
      url: playlistUrl,
      description = '',
    } = playlist || {
      name: undefined,
      seedId: undefined,
      url: undefined,
    };
    const clampedTitle = <Truncate>{name || 'Loading Playlist...'}</Truncate>;
    const clampedDescription = <Truncate lines={2}>{description}</Truncate>;

    const { path = '' } = playlistUrl ? url.parse(playlistUrl) : {};

    return (
      <Eyes2Ears
        stationId={seedId}
        stationType={STATION_TYPE.COLLECTION}
        title={clampedTitle}
        url={path}
      >
        {clampedDescription}
      </Eyes2Ears>
    );
  }
}
