import ArticleAssetImage from './ArticleAssetImage';
import ArticleAssetVideo from './ArticleAssetVideo';
import NavLink from 'components/NavLink';
import {
  ArticleAssetCaption,
  ArticleAssetCaptionContainer,
  ArticleAssetContainer,
  ArticleAssetSource,
} from './primitives';

type Props = {
  alt: string;
  bucket: string;
  caption: string;
  id: string;
  source: string;
  subType: string;
  url?: string;
};

function ArticleAsset({
  bucket,
  caption,
  id,
  source,
  subType,
  url,
  alt,
}: Props) {
  let asset;
  if (subType === 'image') {
    asset =
      url ?
        <NavLink to={url}>
          <ArticleAssetImage alt={alt} bucket={bucket} id={id} />
        </NavLink>
      : <ArticleAssetImage alt={alt} bucket={bucket} id={id} />;
  } else {
    asset = <ArticleAssetVideo bucket={bucket} id={id} />;
  }
  const assetCaption =
    caption ? <ArticleAssetCaption>{caption}</ArticleAssetCaption> : null;
  const assetSource =
    source ?
      <ArticleAssetSource>{`Photo: ${source}`}</ArticleAssetSource>
    : null;

  return (
    <ArticleAssetContainer key={`ArticleAsset-${id}-${subType}`}>
      {asset}
      {assetCaption || assetSource ?
        <ArticleAssetCaptionContainer>
          {assetCaption}
          {assetSource}
        </ArticleAssetCaptionContainer>
      : null}
    </ArticleAssetContainer>
  );
}

export default ArticleAsset;
