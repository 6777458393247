import classnames from 'classnames';
import PlayButtonContainer from './primitives/PlayButtonContainer';
import PlayContainer from 'components/Player/PlayButtonContainer/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import SubTitle from './primitives/SubTitle';
import TextContainer from './primitives/TextContainer';
import theme from 'styles/themes/default';
import TitleLink from './primitives/TitleLink';
import Widget from './primitives/Widget';
import { PureComponent } from 'react';
import { STATION_TYPE } from 'constants/stationTypes';

const PlayButton = PlayerStateProxy(PlayContainer);

class Eyes2Ears extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    stationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    stationType: PropTypes.oneOf([
      STATION_TYPE.LIVE,
      STATION_TYPE.ARTIST,
      STATION_TYPE.PODCAST,
      STATION_TYPE.COLLECTION,
      STATION_TYPE.TRACK,
    ]).isRequired,
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    trackId: PropTypes.number,
    url: PropTypes.string,
  };
  /* eslint-enable react/require-default-props */

  static defaultProps = { title: '' };

  render() {
    const { title, children, stationId, stationType, url, trackId } =
      this.props;

    const stationIdInt = parseInt(stationId, 10);
    const trackIdInt = parseInt(trackId, 10);

    return (
      <Widget>
        <PlayButtonContainer>
          <PlayButton
            className={classnames('play')}
            playedFrom={PLAYED_FROM.PROF_NEWS_MAIN_E2E}
            seedId={stationIdInt}
            stationId={stationIdInt}
            stationType={stationType}
            svgFillColor={theme.colors.black.primary}
            trackId={trackIdInt}
            trackName={title}
          />
        </PlayButtonContainer>
        <TextContainer>
          <TitleLink to={url}>{title}</TitleLink>
          <SubTitle>{children}</SubTitle>
        </TextContainer>
      </Widget>
    );
  }
}

export default Eyes2Ears;
