import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

export const Widget = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 auto',
  marginBottom: '1.5rem',
  minWidth: '25rem',
  paddingLeft: '1.5rem',
  width: '50%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    width: '100%',
  },
}));

export default Widget;
