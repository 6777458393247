import GenericHead from 'components/GenericHead';
import { getSiteUrl } from 'state/Config/selectors';
import { Helmet } from 'react-helmet';
import { parse as parseUrl, resolve as resolveUrl } from 'url';
import { useSelector } from 'react-redux';

type Props = {
  ampEnabled: boolean;
  author: string;
  canonical: string;
  description: string;
  image: string;
  isBranded: boolean;
  keys: Array<string>;
  publishDate: number;
  sections: Array<string>;
  seoTitle: string;
  slug: string;
  socialTitle: string;
  title: string;
};

function ArticleHead({
  author,
  canonical,
  description,
  image,
  keys,
  publishDate,
  slug,
  sections,
  seoTitle,
  socialTitle,
  title = '',
  isBranded = false,
  ampEnabled,
}: Props) {
  const siteUrl = useSelector(getSiteUrl);

  const keywords = title
    .toLowerCase()
    .split(' ')
    .filter(word => word.match(/\w+/gi))
    .join(', ');

  const collection = sections.find(tag => tag.startsWith('collections'));

  const section = collection && collection.split('/').slice(1).join('');

  const tags = keys.map(keyword => keyword.split('/').slice(1)).join(',');

  const link = /^https?:/.test(canonical) ? canonical : `http:${canonical}`;
  const canonicalMatchesSiteUrl =
    canonical && parseUrl(canonical).host === parseUrl(siteUrl).host;

  // eslint-disable-next-line global-require
  const twitter = require('raw-loader!vendor/twitter');

  return (
    <div>
      <GenericHead
        description={`${description} ${
          isBranded ? '[Sponsored Content] ' : ''
        }| iHeart`}
        image={image}
        noAltLink
        ogType="article"
        socialTitle={socialTitle}
        title={seoTitle}
        twitterCard="summary_large_image"
      />
      <Helmet
        link={[
          ...(ampEnabled && canonicalMatchesSiteUrl ?
            [
              {
                href: resolveUrl(siteUrl, `/alternate/amp/${slug}/`),
                rel: 'amphtml',
              },
            ]
          : []),
          { href: canonical, rel: 'canonical' },
        ]}
        meta={
          [
            { content: `${title}, ${keywords}`, name: 'keywords' },
            { content: 'post', name: 'parsely-type' },
            { content: author, name: 'parsely-author' },
            { content: image, name: 'parsely-image-url' },
            { content: image, name: 'twitter:image:src' },
            { content: link, name: 'parsely-link' },
            { content: link, name: 'parsely-network-canonical' },
            {
              content: publishDate && new Date(publishDate).toISOString(),
              name: 'parsely-pub-date',
            },
            { content: section, name: 'parsely-section' },
            { content: seoTitle, name: 'parsely-title' },
            { content: tags, name: 'parsely-tags' },
            ...(isBranded ? [{ content: 'noindex', name: 'taboolabot' }] : []),
          ] as Array<{ content: string; name: string }>
        }
        script={[
          {
            innerHTML: twitter.default ?? twitter,
            type: 'text/javascript',
          },
        ]}
      />
    </div>
  );
}

export default ArticleHead;
