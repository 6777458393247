import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

export const Header = styled('h1')(({ theme }) => ({
  fontSize: '3.6rem',
  fontWeight: 500,
  lineHeight: '4.5rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    fontSize: '3rem',
    lineHeight: '3.8rem',
  },

  '@media only screen and (max-width: 540px)': {
    fontSize: '2.2rem',
    lineHeight: '2.8rem',
  },
}));
