import styled from '@emotion/styled';

const ArticleAssetSource = styled('div')(({ theme }) => ({
  color: theme.colors.gray['400'],
  fontSize: theme.fonts.size['12'],
  lineHeight: theme.fonts.lineHeight['16'],
  margin: '0.4rem 0',
}));

export default ArticleAssetSource;
