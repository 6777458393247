import styled from '@emotion/styled';
import { buttonSize } from './mixins';

const PlayButtonContainer = styled('div')({
  button: {
    alignSelf: 'flex-start',
    height: buttonSize,
    width: buttonSize,
  },
});

export default PlayButtonContainer;
