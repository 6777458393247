import styled from '@emotion/styled';

interface Props {
  'data-test'?: string;
}

const E2EContainer = styled('div')<Props>(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.gray['300']}`,
  borderTop: `1px solid ${theme.colors.gray['300']}`,
  margin: '3rem 0',
  padding: '1.5rem 0 0 0',
  width: '100%',
}));

export default E2EContainer;
