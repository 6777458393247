import styled from '@emotion/styled';
import { css, WithTheme } from '@emotion/react';
import type { Props } from './BlockContainer';
import type { Theme } from 'styles/themes/default';

export const htmlStyles = ({ theme }: WithTheme<Props, Theme>) =>
  css({
    '& .warning-text': {
      color: theme.colors.red['600'],
      fontWeight: 'bold',
    },

    a: {
      color: `${theme.colors.gray.dark}`,
      fontWeight: 'bold',
      textDecoration: 'none',
    },

    blockquote: {
      borderLeft: `4px solid ${theme.colors.red.primary}`,
      color: '$dark-grey',
      fontSize: '1.7rem',
      lineHeight: '2.295rem',
      margin: '4.5rem 1.5rem',
      paddingLeft: '1.5rem',
      textAlign: 'left',
    },

    body: {
      color: `${theme.colors.gray.medium}`,
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontSize: '1.6rem',
      height: 'fit-content',
      margin: 0,
      overflow: 'hidden',
      position: 'relative',
    },

    h2: {
      color: `${theme.colors.gray.dark}`,
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: '2.6rem',
    },

    h3: {
      color: `${theme.colors.gray.dark}`,
      fontSize: '2.2rem',
      fontWeight: 700,
      lineHeight: '2.8rem',
    },

    h4: {
      color: `${theme.colors.gray.dark}`,
      lineHeight: '2.4rem',
    },

    html: {
      fontSize: '10px',
    },

    'img.fr-dib': {
      display: 'block',
      float: 'none',
      margin: '0.5rem auto',
      verticalAlign: 'top',
    },

    'img.fr-dib.fr-fil': {
      marginLeft: 0,
    },

    'img.fr-dib.fr-fir': {
      marginRight: 0,
    },

    'img.fr-dii': {
      display: 'inline-block',
      float: 'none',
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      maxWidth: 'calc(100% - 1rem)',
      verticalAlign: 'bottom',
    },

    'img.fr-dii.fr-fil': {
      float: 'left',
      margin: '0.5rem 0.5rem 0.5rem 0',
      maxWidth: 'calc(100% - 1rem)',
    },

    'img.fr-dii.fr-fir': {
      float: 'right',
      margin: '0.5rem 0 0.5rem 0.5rem',
      maxWidth: 'calc(100% - 0.5rem)',
    },

    li: {
      color: '$dark-grey',
      lineHeight: '2.4rem',
      marginBottom: '1.5rem',
      paddingLeft: '3.04rem',
      textIndent: '-2.4rem',
    },

    ol: {
      counterReset: 'item',
    },

    'ol li': {
      counterIncrement: 'item',
    },

    'ol li::before': {
      content: "counter(item) '.'",
      display: 'inline-block',
      fontWeight: 'bold',
      paddingRight: '0.8rem',
      textAlign: 'right',
      width: '2.4rem',
    },

    'ol, ul': {
      listStyle: 'none',
      margin: '3rem 0',
      padding: 0,
    },

    p: {
      clear: 'both',
      margin: '0 0 3rem 0',
      padding: 0,
    },

    'ul li::before': {
      color: `${theme.colors.red.primary}`,
      content: '"•"',
      display: 'inline-block',
      fontSize: '3rem',
      fontWeight: 'bold',
      marginBottom: '0.1rem',
      paddingRight: '0.8rem',
      textAlign: 'right',
      verticalAlign: 'middle',
      width: '2.1rem',
    },
  });

const ArticleHtml = styled('div')(htmlStyles);

export default ArticleHtml;
