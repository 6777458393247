import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface Props {
  'data-test'?: string;
}

export const gallery = css({
  margin: '1rem 0 2rem',

  '& img': {
    // IHRWEB-13301 UXD: for now only gallery images have rounded corners
    borderRadius: '0.6rem',
  },
});

const GalleryContainer = styled('div')<Props>(gallery);

export default GalleryContainer;
