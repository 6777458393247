import ArticleAuthor from 'components/ArticleAuthor';
import ArticlePublishDate from 'components/ArticlePublishDate';
import ArticleVendor from 'components/ArticleVendor';
import format from 'date-fns/format';
import useTranslate from 'contexts/TranslateContext/useTranslate';

export type Props = {
  author: string;
  feedVendor: string;
  isBranded: boolean;
  pubDate: number;
  showUpdateDate: boolean | number;
  updateDate: number;
};

const generateByline = (
  isBranded: boolean,
  feedVendor: string,
  author: string,
) => {
  if (isBranded) {
    return null;
  }

  if (feedVendor) {
    return <ArticleVendor author={author} feedVendor={feedVendor} />;
  }

  return <ArticleAuthor author={author} />;
};

function ArticleSubtitle({
  pubDate,
  updateDate,
  showUpdateDate,
  author,
  isBranded,
  feedVendor,
}: Props) {
  const translate = useTranslate();

  let date = null;
  let updateDateAndTime = null;
  if (pubDate) {
    date = format(new Date(pubDate), 'MMMM D, YYYY');
    updateDateAndTime = format(new Date(updateDate), 'MMM D, YYYY h:mm A');
  }

  return (
    <div>
      {generateByline(isBranded, feedVendor, author)}
      {date && <ArticlePublishDate date={date} />}
      {showUpdateDate && updateDateAndTime ?
        <ArticlePublishDate
          date={translate('Updated {updateDateAndTime}', { updateDateAndTime })}
        />
      : null}
    </div>
  );
}

export default ArticleSubtitle;
