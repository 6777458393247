import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

export const TitleLink = styled(NavLink)(({ theme }) => ({
  color: theme.colors.gray.dark,
  display: 'block',
  fontSize: '1.6rem',
  lineHeight: 'calc(1em + 0.1rem)',
  marginBottom: '0.7rem',
}));

export default TitleLink;
