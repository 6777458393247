import styled from '@emotion/styled';

interface Props {
  'data-test'?: string;
}

const ArticleBodyContainer = styled('div')<Props>(({ theme }) => ({
  color: theme.colors.gray.medium,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  margin: '1.5rem 0 4.5rem',
}));

export default ArticleBodyContainer;
