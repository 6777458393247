import Track from './Track';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAmpUrl } from 'state/Config/selectors';
import { getSimilarArtists } from 'state/Artists/actions';
import { getTrack, getTrackSimilars } from 'state/Tracks/selectors';
import { requestTracks } from 'state/Tracks/actions';

export default connect(
  createStructuredSelector({
    ampUrl: getAmpUrl,
    similars: getTrackSimilars(3),
    track: getTrack,
  }),
  { getSimilarArtists, requestTracks },
)(Track);
