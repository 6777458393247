import styled from '@emotion/styled';
import { BlockType } from '../types';
import { css } from '@emotion/react';
import { EmbedProvider } from '../EmbedBlock/types';
import { gallery } from './GalleryContainer';
import { htmlStyles } from './ArticleHtml';
import { notFound } from './NotFound';
import { ReactNode } from 'react';
import type { Theme } from 'styles/themes/default';
import type { WithTheme } from '@emotion/react';

export type Props = {
  'data-test'?: string;
  children: ReactNode;
  provider: EmbedProvider;
  type: BlockType;
};

const embed = css({
  '&:first-of-type': {
    marginTop: 0,
  },

  display: 'block',
  marginBottom: '2rem',
  marginTop: '3rem',
  textAlign: 'center',
  zIndex: 1,
});

const container = ({ type, provider }: Props) => {
  const baseContainerStyles = css({
    '& > *': {
      '& iframe': {
        maxWidth: '100%',
      },
      maxWidth: '100%',
    },
  });

  if (provider === EmbedProvider.YouTube || type === BlockType.Ohembed) {
    return css(baseContainerStyles, {
      '& > *': {
        height: 0,
        maxWidth: '100%',
        overflow: 'hidden',
        paddingBottom: '56.25%',
        position: 'relative',
      },
    });
  } else {
    return baseContainerStyles;
  }
};

const providerStyles = ({ provider }: Props) => {
  if (provider === EmbedProvider.Facebook) {
    return css(embed, { paddingBottom: '2rem' });
  }

  if (provider === EmbedProvider.Twitter) {
    return css(embed, {
      paddingBottom: '1rem',

      // component provided by twitter
      twitterwidget: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    });
  }

  if (provider === EmbedProvider.Instagram) {
    return css(embed, {
      iframe: {
        // WEB-8428 important is used to override the automatic styling provided by the instagram embed
        margin: 'auto auto 3rem auto !important',
      },
      paddingBottom: '2rem',
    });
  }

  if (provider === EmbedProvider.YouTube) {
    return css({
      iframe: {
        display: 'block',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      },

      clear: 'both',
      height: 0,
      marginBottom: '2rem',
      paddingBottom: '56.25%',
    });
  }

  return css({});
};

const blockTypeStyles = ({ type, theme }: WithTheme<Props, Theme>) => {
  if (type === BlockType.E2E) {
    return css({
      borderBottom: `1px solid ${theme.colors.gray['300']}`,
      borderTop: `1px solid ${theme.colors.gray['300']}`,
      margin: '3rem 0',
      padding: '1.5rem 0 0 0',
      width: '100%',
    });
  }

  if (type === BlockType.Gallery) {
    return gallery;
  }

  if (type === BlockType.Html) {
    return htmlStyles;
  }

  if (type === BlockType.NotFound) {
    return notFound;
  }

  if (type === BlockType.Ohembed) {
    return css(embed, {
      iframe: {
        height: '100%',
        left: '0',
        position: 'absolute',
        top: '0',
        width: '100%',
      },

      marginBottom: '3rem',
    });
  }

  return css({});
};

const BlockContainer = styled('section')<Props>(
  container,
  providerStyles,
  blockTypeStyles,
);

export default BlockContainer;
