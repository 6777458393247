import ArticleSubtitle, { Props } from './ArticleSubtitle';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getAuthor,
  getFeedVendor,
  getIsBranded,
  getPublishDate,
  getShowUpdateDate,
  getUpdateDate,
} from 'state/News/selectors';
import { State } from 'state/types';

export default connect(
  createStructuredSelector<
    State,
    Pick<
      Props,
      | 'author'
      | 'feedVendor'
      | 'isBranded'
      | 'pubDate'
      | 'showUpdateDate'
      | 'updateDate'
    >
  >({
    author: getAuthor,
    feedVendor: getFeedVendor,
    isBranded: getIsBranded,
    pubDate: getPublishDate,
    showUpdateDate: getShowUpdateDate,
    updateDate: getUpdateDate,
  }),
)(ArticleSubtitle);
