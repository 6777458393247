import ArticleBody from './ArticleBody';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getBody, getFeedPermalink, getFeedVendor } from 'state/News/selectors';

export default connect(
  createStructuredSelector({
    blocks: getBody,
    feedPermalink: getFeedPermalink,
    feedVendor: getFeedVendor,
  }),
)(ArticleBody);
