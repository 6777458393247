import AddThis from 'components/AddThis/AddThis';
import AddThisBottom from './primitives/AddThisBottom';
import ArticleBody from 'components/ArticleBody';
import ArticleHead from 'components/ArticleHead';
import ArticleSubtitle from 'components/ArticleSubtitle';
import ArticleTitle from 'components/ArticleTitle';
import ArticleTopics from 'components/ArticleTopics';
import Eyebrow from './primitives/Eyebrow';
import getScript from 'utils/getScript';
import Outbrain from 'components/Outbrain';
import PageBody, { MainStyles, ViewName } from 'views/PageBody';
import Parsely from 'components/Parsely';
import Row from './primitives/Row';
import SponsoredDisclosure from './primitives/SponsoredDisclosure';
import Subtitle from './primitives/Subtitle';
import SubtitlePart from './primitives/SubtitlePart';
import { Article } from 'state/News/types';
import { Component } from 'react';
import { get } from 'lodash-es';

type Props = {
  adKeywords: string;
  adTopics: string;
  ampEnabled?: boolean;
  article: Article;
  author: string;
  feedVendor: string;
  hasInstagram?: boolean;
  hasTwitter?: boolean;
  isBranded: boolean;
  isInApp?: boolean;
  keywords: Array<string>;
  publishDate: number;
  setHasHero: (hasHero: boolean) => void;
  siteUrl: string;
  slug: string;
  tags: Array<string>;
  blockedPIIBehaviors: {
    sanitizeAds: boolean;
    sanitizeStreams: boolean;
    turnOffAndo: boolean;
    turnOffOutbrain: boolean;
  };
  subInfoLoaded: boolean;
};

class ContentArticle extends Component<Props> {
  static defaultProps = {
    hasInstagram: false,
    hasTwitter: false,
    isBranded: false,
    isInApp: false,
  };

  state = {
    instagramReady: !__CLIENT__ || !!get(window, ['instgrm', 'Embeds']),
  };

  componentDidMount() {
    const { setHasHero } = this.props;
    setHasHero(false);
    this.processSpaEmbeds();
  }

  UNSAFE_componentWillUpdate(nextProps: Props) {
    const { isBranded, slug } = this.props;
    if (slug !== nextProps.slug && isBranded && window.SPR && window.SPR.stop) {
      window.SPR.stop();
    }
  }

  componentDidUpdate() {
    this.processSpaEmbeds();
  }

  componentWillUnmount() {
    const { isBranded } = this.props;
    if (isBranded && window.SPR && window.SPR.stop) {
      window.SPR.stop();
    }
  }

  processSpaEmbeds() {
    const { hasTwitter, hasInstagram } = this.props;
    const { instagramReady } = this.state;

    // WEB-9233 window.twttr.widgets is defined in an ansync script, if it is already defined
    // we need to manually refire the load event to ensure that twitter embeds load correctly
    if (hasTwitter && window.twttr && window.twttr.widgets)
      window.twttr.widgets.load();

    // WEB-9231 window.instgrm needs to be handled the same way as window.twttr
    if (hasInstagram && !instagramReady) {
      getScript(
        '//platform.instagram.com/en_US/embeds.js',
        'instagramEmbedScript',
        {
          async: true,
        },
      ).then(() => {
        this.setState({
          instagramReady: true,
        });
      });
    }

    if (hasInstagram && instagramReady) {
      window.instgrm.Embeds.process();
    }
  }

  generateEyebrow = (isBranded: boolean, feedVendor: string) => {
    if (isBranded) {
      return <Eyebrow>Sponsored Content</Eyebrow>;
    }

    if (feedVendor) {
      return <Eyebrow>Partner Content</Eyebrow>;
    }

    return null;
  };

  mainStyles: MainStyles = ({ mediaQueryBuilder, theme }) => ({
    paddingRight: '3rem',
    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      paddingRight: 0,
    },
  });

  render() {
    const {
      article,
      hasInstagram,
      isInApp,
      isBranded,
      ampEnabled,
      author,
      slug,
      keywords,
      tags,
      feedVendor,
      blockedPIIBehaviors,
      subInfoLoaded,
    } = this.props;

    if (!article) return null;

    const permalink = `https://www.iheart.com/content/${slug}/`;

    const articleBodyObj =
      article?.blocks?.find(obj => obj.type === 'html')?.html ?? '';
    const articleBody = decodeURI(articleBodyObj);

    const markup = {
      articleBody,
      author: article.author,
      dateModified: article.update_date,
      datePublished: article.publish_date,
      headline: article.title,
      image: article['@img'] ?? undefined,
      publisher: article.publish_origin ?? undefined,
    };

    const enableOutbrainRecs =
      !blockedPIIBehaviors.turnOffOutbrain &&
      slug &&
      subInfoLoaded &&
      !article.is_sponsored &&
      article.include_recommendations;

    return (
      <>
        {get(article, 'error') ? null : (
          <ArticleHead
            ampEnabled={ampEnabled as boolean}
            author={author}
            canonical={get(article, 'canonical_url')}
            description={get(article, 'summary')}
            image={article['@img']}
            isBranded={isBranded}
            keys={keywords}
            publishDate={get(article, 'publish_date')}
            sections={tags}
            seoTitle={get(article, 'seo_title')}
            slug={slug}
            socialTitle={get(article, 'social_title')}
            title={get(article, 'title')}
          />
        )}
        <PageBody
          dataTest={ViewName.NewsContentArticle}
          hideFooter={isInApp}
          mainStyles={this.mainStyles}
          richResultsMarkup={markup}
        >
          <article css={{ marginLeft: 'auto', marginRight: 'auto' }}>
            {this.generateEyebrow(isBranded, feedVendor)}
            <ArticleTitle />
            <Subtitle>
              <SubtitlePart>
                <ArticleSubtitle />
              </SubtitlePart>
              <SubtitlePart data-test="add-this-header">
                {article.error ? null : (
                  <AddThis link={permalink} title={article.social_title} />
                )}
              </SubtitlePart>
            </Subtitle>
            <ArticleBody />
            <Row>
              <ArticleTopics />
            </Row>
            {isBranded && author ?
              <SponsoredDisclosure>
                <span>
                  This paid content is provided by {author}. The views and
                  opinions expressed do not necessarily represent those of
                  iHeart.
                </span>
              </SponsoredDisclosure>
            : null}
            {get(article, 'error') ? null : (
              <div>
                <AddThisBottom
                  link={permalink}
                  title={get(article, 'social_title')}
                />
              </div>
            )}
            {enableOutbrainRecs ?
              <Outbrain permalink={permalink} />
            : null}
            {(
              article.dataskriveAnalyticsToken && article.dataskriveContentToken
            ) ?
              <>
                <span
                  data-dsid-analytics={article.dataskriveAnalyticsToken}
                  data-dsid-content={article.dataskriveContentToken}
                  id="ds_data"
                />
                <script
                  src="https://cdn.dataskrive.com/scripts/ds_ga.js"
                  type="text/javascript"
                />
              </>
            : null}
          </article>
        </PageBody>
        <Parsely />
        {hasInstagram && !__CLIENT__ ?
          <script async defer src="//platform.instagram.com/en_US/embeds.js" />
        : null}
      </>
    );
  }
}

export default ContentArticle;
