import styled from '@emotion/styled';

const SponsoredDisclosure = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.gray.secondary,
  marginBottom: '3rem',
  marginTop: '3rem',
  padding: '1.5rem',
  textAlign: 'left',

  span: {
    color: theme.colors.gray.medium,
    fontSize: '1.6rem',
    letterSpacing: 'normal',
    lineHeight: '1.25rem',
  },
}));

export default SponsoredDisclosure;
