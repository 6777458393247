import styled from '@emotion/styled';

const ArticleAssetCaption = styled('div')(({ theme }) => ({
  color: theme.colors.gray['600'],
  fontSize: theme.fonts.size['14'],
  lineHeight: theme.fonts.lineHeight['18'],
  margin: '0.4rem 0',
}));

export default ArticleAssetCaption;
