import ArticleTopics from './ArticleTopics';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { getKeywords } from 'state/News/selectors';
import { slugify } from 'utils/string';

const processTopics = createSelector(getKeywords, topics =>
  topics
    .filter(keyword => keyword.match(/^artists\//))
    .map(topic => {
      const [, , name, id] = /(\w+)\/(.+) \((\d+)\)/gi.exec(topic);
      return {
        name,
        url: `/artist/${slugify(name)}-${id}/`,
      };
    }),
);

export default connect(createStructuredSelector({ topics: processTopics }))(
  ArticleTopics,
);
