import PodcastE2E from './Podcast';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getPodcastById, getPodcastEpisodes } from 'state/Podcast/selectors';
import {
  getPodcastEpisodeWithAuth,
  requestPodcast,
} from 'state/Podcast/actions';

export default connect(
  createStructuredSelector({
    podcast: getPodcastById,
    episodes: getPodcastEpisodes,
  }),
  {
    getPodcastData: requestPodcast,
    getEpisodeData: getPodcastEpisodeWithAuth,
  },
)(PodcastE2E);
