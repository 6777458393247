import styled from '@emotion/styled';

const ArticleAssetContainer = styled('div')({
  margin: '1.6rem 0 3.6rem',

  '& > img': {
    // prevent extra space between img & close of div
    display: 'block',
  },
});

export default ArticleAssetContainer;
