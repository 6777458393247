import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const Link = styled(NavLink)(({ theme }) => ({
  color: `${theme.colors.black.secondary}`,
  fontWeight: 700,
  marginRight: '1rem',
  textDecoration: 'none',
}));

export default Link;
