import ArticleAsset from 'components/ArticleAsset';
import ArticleBodyContainer from './primitives/ArticleBodyContainer';
import ArtistE2E from 'components/Eyes2Ears/Artist';
import BlockContainer from './primitives/BlockContainer';
import ContentBlock from './ContentBlock';
import E2EContainer from './primitives/E2EContainer';
import EmbedBlock from './EmbedBlock';
import FilledButton from 'primitives/Buttons/FilledButton';
import GalleryContainer from './primitives/GalleryContainer';
import GalleryImageNumber from './primitives/GalleryImageNumber';
import LiveE2E from 'components/Eyes2Ears/Live';
import NavLink from 'components/NavLink';
import NotFound from './primitives/NotFound';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PlaylistE2E from 'components/Eyes2Ears/Playlist';
import PodcastE2E from 'components/Eyes2Ears/Podcast';
import TrackE2E from 'components/Eyes2Ears/Track';
import { BlockKind, BlockType } from './types';
import { Fragment } from 'react';
import type { EmbedProvider } from './EmbedBlock/types';

/* eslint-disable camelcase */
type Props = {
  blocks: Array<{
    attributes?: {
      type: string;
      height: string;
      width: string;
    };
    bucket: string;
    caption: string;
    html: string;
    id: string;
    kind: BlockKind;
    schedule: {
      begin: number;
      end: number;
    };
    source_credit: string;
    related_id: string;
    use_caption: boolean;
    provider: EmbedProvider;
    title: string;
    type: BlockType;
    url: string;
    assets?: Array<{
      bucket: string;
      caption: string;
      id: string;
      source_credit: string;
      title: string;
      use_caption: boolean;
    }>;
  }>;
  feedPermalink: string;
  feedVendor: string;
};
/* eslint-enable camelcase */

function ArticleBody({ blocks, feedPermalink, feedVendor }: Props) {
  return (
    <ArticleBodyContainer>
      {blocks
        .filter(block => {
          const now = Date.now();
          const begin = (block?.schedule?.begin ?? Date.now()) - 1;
          const end = (block?.schedule?.end ?? Date.now()) + 1;
          return begin <= now && end >= now;
        })
        .map((block, idx) => {
          const blockKey = `${block.id ?? idx}-${block.kind ?? block.type}`;

          if (block.html) {
            return (
              <BlockContainer
                key={`topic-${blockKey}`}
                provider={block.provider}
                type={block.type}
              >
                {block.type === BlockType.Embed ?
                  <EmbedBlock
                    height={Number(block?.attributes?.height)}
                    html={block.html}
                    provider={block.provider}
                    width={Number(block?.attributes?.width)}
                  />
                : <ContentBlock html={block.html} />}
              </BlockContainer>
            );
          }

          if (block.type === BlockType.NotFound) {
            return (
              <NotFound key={`topic-${blockKey}`}>
                <NavLink target="_blank" to="https://news.iheart.com/news/">
                  <FilledButton styleType="cta">Go to Latest News</FilledButton>
                </NavLink>
                <NavLink to="/">
                  <OutlinedButton>Go to Homepage</OutlinedButton>
                </NavLink>
              </NotFound>
            );
          }

          if (
            block.type === BlockType.Catalog &&
            block.kind === BlockKind.Station
          ) {
            return (
              <E2EContainer key={`e2e-${blockKey}`}>
                <LiveE2E stationId={block.id} />
              </E2EContainer>
            );
          }

          if (
            block.type === BlockType.Catalog &&
            [BlockKind.TalkShow, BlockKind.Podcast].includes(block.kind)
          ) {
            return (
              <E2EContainer key={`e2e-${blockKey}`}>
                <PodcastE2E episodeId={block.related_id} podcastId={block.id} />
              </E2EContainer>
            );
          }

          if (
            block.type === BlockType.Catalog &&
            block.kind === BlockKind.Artist
          ) {
            return (
              <E2EContainer key={`e2e-${blockKey}`}>
                <ArtistE2E artistId={block.id} />
              </E2EContainer>
            );
          }

          if (
            block.type === BlockType.Catalog &&
            block.kind === BlockKind.Track
          ) {
            return (
              <E2EContainer key={`e2e-${blockKey}`}>
                <TrackE2E trackId={block.id} />
              </E2EContainer>
            );
          }

          if (
            block.type === BlockType.Catalog &&
            block.kind === BlockKind.Curated
          ) {
            const [playlistUserId, playlistId] = (block.id || '').split('::');
            return (
              <E2EContainer key={`e2e-${blockKey}`}>
                <PlaylistE2E
                  playlistId={playlistId}
                  playlistUserId={playlistUserId}
                />
              </E2EContainer>
            );
          }

          if (block.type === BlockType.Asset) {
            return (
              <ArticleAsset
                alt={block.title}
                bucket={block.bucket}
                caption={
                  block.use_caption && block.caption ? block.caption : ''
                }
                id={block.id}
                key={`asset-${block.id}`}
                source={block.source_credit || ''}
                subType={block?.attributes?.type ?? 'image'}
                url={block.url}
              />
            );
          }

          if (block.type === BlockType.Gallery) {
            return (
              <GalleryContainer
                key={`gallery-${block?.assets?.[0]?.id ?? idx}`}
              >
                {block?.assets?.map((galleryImage, imgIdx, gallery) => (
                  <Fragment key={`gallery-image-${galleryImage.id}`}>
                    <GalleryImageNumber>{`${imgIdx + 1} of ${
                      gallery.length
                    }`}</GalleryImageNumber>
                    <ArticleAsset
                      alt={galleryImage.title}
                      bucket={galleryImage.bucket}
                      caption={
                        galleryImage.use_caption && galleryImage.caption ?
                          galleryImage.caption
                        : ''
                      }
                      id={galleryImage.id}
                      source={galleryImage.source_credit || ''}
                      subType="image"
                    />
                  </Fragment>
                ))}
              </GalleryContainer>
            );
          }

          return null;
        })}
      {feedVendor ?
        <p>
          <em>
            This story originally appeared in{' '}
            <a href={feedPermalink} rel="noopener noreferrer" target="_blank">
              <strong>{feedVendor}</strong>
            </a>
          </em>
        </p>
      : null}
    </ArticleBodyContainer>
  );
}

export default ArticleBody;
