import styled from '@emotion/styled';

export const ArticleAssetVideoContainer = styled('div')({
  '& > iframe': {
    border: 'none',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  paddingTop: '56.25%',
  position: 'relative',
});
