import ArticleAssetImage from './ArticleAssetImage';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { getMediaServerUrl } from 'state/Config/selectors';
import { State } from 'state/buildInitialState';

export default flowRight(
  connect(
    createStructuredSelector<State, { mediaServerUrl: string }>({
      mediaServerUrl: getMediaServerUrl,
    }),
  ),
)(ArticleAssetImage);
