import Artist from './Artist';
import { Artist as ArtistType } from 'state/Artists/types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAmpUrl } from 'state/Config/selectors';
import { getArtist, getArtistSimilars } from 'state/Artists/selectors';
import { getSimilarArtists, requestArtist } from 'state/Artists/actions';
import { State } from 'state/types';

export default connect(
  createStructuredSelector<
    State,
    { artistId: string },
    { ampUrl: string; artist: ArtistType; similars: Array<ArtistType> }
  >({
    ampUrl: getAmpUrl,
    artist: getArtist,
    similars: getArtistSimilars(3),
  }),
  { getSimilarArtists, requestArtist },
)(Artist);
