import styled from '@emotion/styled';

const OutbrainTitleNavigation = styled('div')(({ theme }) => ({
  a: {
    color: theme.colors.gray.dark,
    display: 'block',
    margin: '0 auto',
    width: '84%',
  },
}));

export default OutbrainTitleNavigation;
