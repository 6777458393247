import { contain } from 'utils/mediaServerImageMaker/opsString';

export type Props = {
  alt: string;
  bucket: string;
  id: string;
  mediaServerUrl: string;
};

function ArticleAssetImage({ bucket, id, mediaServerUrl, alt }: Props) {
  return (
    <img
      alt={alt}
      src={`${mediaServerUrl}/v3/re/${bucket}${id}?ops=${contain(740, 0, '')}`}
    />
  );
}

export default ArticleAssetImage;
