import styled from '@emotion/styled';

const Label = styled('label')(({ theme }) => ({
  color: `${theme.colors.red.primary}`,
  fontSize: '1.3rem',
  fontWeight: 400,
  marginRight: '1.5rem',
  textTransform: 'uppercase',
}));

export default Label;
