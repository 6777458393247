import Playlist, { Props } from './Playlist';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getPlaylist } from 'state/Playlist/selectors';
import { Playlist as PlaylistType } from 'state/Playlist/types';
import { requestPlaylist } from 'state/Playlist/actions';
import { State } from 'state/types';

type StateProps = { playlist: PlaylistType };

type DispatchProps = {
  requestPlaylist: (playlist: {
    playlistId: string;
    playlistUserId: string;
  }) => void;
};

type OwnProps = Pick<
  Props,
  Exclude<keyof Props, keyof StateProps | keyof DispatchProps>
>;

const mapStateToProps = createStructuredSelector<State, OwnProps, StateProps>({
  playlist: getPlaylist,
});

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  {
    requestPlaylist,
  },
)(Playlist);
