import useMount from 'hooks/useMount';
import { useRef } from 'react';
import type {
  FunctionComponent,
  MutableRefObject,
  SyntheticEvent,
} from 'react';
import type { Navigate } from 'state/Routing/types';

type NullableDiv = HTMLDivElement | null;
type Ref = MutableRefObject<NullableDiv>;
type TargetWithHref = EventTarget & { href: string };
type OnClickEvent = SyntheticEvent<HTMLDivElement> | MouseEvent;

type Props = {
  className?: string;
  html: string;
  navigate?: Navigate;
};

const ContentBlock: FunctionComponent<Props> = ({
  className,
  html,
  navigate,
}) => {
  const ref = useRef<NullableDiv>(null);

  const handleLinks = (el: Ref, callback: (a: HTMLAnchorElement) => void) => {
    Array.from(el?.current?.querySelectorAll('a') ?? []).forEach(callback);
  };

  const onClick = (e: OnClickEvent): void => {
    const target = e.currentTarget as TargetWithHref;

    /**
     * Sometimes this is undefined. If so, we should just return early.
     */
    if (!target) return;
    try {
      const url = new URL(target?.href);
      const isIHeart = url?.hostname?.includes('www.iheart.com') || false;

      // WEB-9237 it is possible that url.path can include /content/ in the context of news.iheart.com/featured/<slug>/content
      // this ensures it only treats the issue internally if the first path segment is /content/
      const isContent =
        url?.hostname?.includes('news.iheart.com') &&
        url?.pathname?.startsWith('/content/');

      const currentLocation = new URL(window.location.href);

      const isAnchor =
        url?.hash !== null && url?.hostname === currentLocation.hostname;

      if (isAnchor) {
        const scrollToY = document
          .querySelector(`${url.hash}`)
          ?.getBoundingClientRect().top;

        if (scrollToY) {
          e.preventDefault();
          window.scrollTo({
            top: scrollToY + window.scrollY - 55,
            behavior: 'smooth',
          });
        }
      } else if ((isIHeart || isContent) && url.pathname && navigate) {
        e.preventDefault();
        navigate({ path: url.pathname });
      }
    } catch {
      // nothing necessary
    }
  };

  useMount(() => {
    // WEB-9237 this is necessary as opposed to adding a click event to the container because
    // <a><strong>Foo</strong></a> is valid and will never recognize an anchor as the event currentTarget
    handleLinks(ref, (el: HTMLAnchorElement) =>
      el.addEventListener('click', onClick),
    );

    return () => {
      handleLinks(ref, (el: HTMLAnchorElement) =>
        el.removeEventListener('click', onClick),
      );
    };
  });

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
      onClick={onClick}
      ref={ref}
    />
  );
};

export default ContentBlock;
