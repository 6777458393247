/* eslint-disable camelcase */
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import getScript from 'utils/getScript';
import NavLink from 'components/NavLink';
import Section from 'components/Section';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import TitleNavigation from './primitives/TitleNavigation';
import Truncate from 'components/Truncate';
import { memoize } from 'lodash-es';
import { PureComponent } from 'react';
import { TILE_RES } from 'components/MediaServerImage';

type Props = {
  permalink: string;
};

type Article = {
  content: string;
  orig_url: string;
  source_name: string;
  thumbnail: {
    url: string;
  };
  url: string;
};

type Item = {
  description: string | null;
  imgUrl: string;
  imgWidth: number;
  key: string;
  name: string;
  onClick: (() => Promise<void>) | undefined;
  url: string;
};

type State = {
  data: Array<Item>;
};

class Outbrain extends PureComponent<Props, State> {
  state = {
    data: [],
  };

  isReady = memoize(async (): Promise<void> => {
    if (window.OBR && window.OBR.extern && window.OBR.extern.callRecs) return;
    await getScript('//widgets.outbrain.com/outbrain.js', 'outbrainScript');
  });

  componentDidMount(): void {
    this.getRecs(this.props.permalink);
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.permalink === this.props.permalink) return;
    this.getRecs(this.props.permalink);
  }

  getRecs = async (permalink: string): Promise<void> => {
    await this.isReady();

    window.OBR.extern.callRecs(
      {
        permalink,
        templateId: 'clearchannel',
        widgetId: 'JS_1',
      },
      ({ doc: articles = [] }: { doc?: Array<Article> } = {}): void => {
        const data = articles.map((article: Article): Item => {
          const {
            content,
            orig_url: origUrl,
            source_name: sourceName,
            thumbnail,
          } = article;
          const iheartContent = origUrl.indexOf('www.iheart.com') > -1;
          const url =
            iheartContent ?
              origUrl.replace(/http(s?):\/\/www\.iheart\.com/gi, '')
            : article.url;

          return {
            description: iheartContent ? null : `by ${sourceName}`,
            imgUrl: thumbnail.url,
            imgWidth: TILE_RES,
            key: url,
            name: content,
            onClick:
              iheartContent ?
                (): Promise<void> => this.trackClick(article.url)
              : undefined,
            url,
          };
        });

        this.setState({ data });
      },
    );
  };

  async trackClick(link: string): Promise<void> {
    await this.isReady();
    window.OBR.extern.callClick({ link });
  }

  render() {
    const { data } = this.state;

    if (!data.length) return null;

    return (
      <Section header="You Might Also Like">
        <Tiles tilesInRow={3}>
          {data.map(({ imgUrl, imgWidth, key, name, url }) => (
            <Tile
              key={key}
              subTitle={
                <TitleNavigation data-test="article-title">
                  <NavLink to={url}>
                    <Truncate lines={3}>{name}</Truncate>
                  </NavLink>
                </TitleNavigation>
              }
              tilesInRow={3}
              url={url}
            >
              <NavLink to={url}>
                <CatalogImage
                  alt={name}
                  aspectRatio={1}
                  height={imgWidth}
                  src={imgUrl}
                  width={imgWidth}
                />
              </NavLink>
            </Tile>
          ))}
        </Tiles>
      </Section>
    );
  }
}

export default Outbrain;
