import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface Props {
  'data-test'?: string;
}

export const notFound = css({
  button: {
    margin: '0.5rem',
  },
  marginTop: '3rem',
  textAlign: 'center',
});

const NotFound = styled('section')<Props>(notFound);

export default NotFound;
