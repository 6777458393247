type Props = {
  author: string;
  feedVendor: string;
};

function ArticleVendor({ feedVendor, author }: Props) {
  return (
    <p>
      By {author ? `${author}, ` : ''}
      {feedVendor}
    </p>
  );
}

export default ArticleVendor;
