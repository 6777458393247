import { ArticleAssetVideoContainer } from './primitives/ArticleAssetVideoContainer';

type Props = {
  bucket: string;
  id: string;
};

function ArticleAssetVideo({ bucket, id }: Props) {
  return (
    <ArticleAssetVideoContainer>
      <iframe
        allowFullScreen
        frameBorder="0"
        scrolling="no"
        src={`https://www.iheart.com/video/play/?embed=true&autoStart=false&useFullScreen=true&reid=${bucket}${id}`}
        title="video player"
      />
    </ArticleAssetVideoContainer>
  );
}

export default ArticleAssetVideo;
